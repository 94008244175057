import { updateFileMetaData } from './api';
import C from '../constants';
import { updateFilterProperties } from 'processor/dataProcessor';
import { importDataStructure } from 'data/importDataStructure';
import { addError } from 'redux/slices/errorReducer';
import { setFilterIndex } from 'redux/slices/filterIndex';
import { setRecords } from 'redux/slices/ledgerFileReducer';
import { deleteFile, logFileLastUpdateReducer, setFile } from 'redux/slices/metaFilesReducer';
import { addNoteReducer, updateActiveNoteIdReducer } from 'redux/slices/notesReducer';
import { updatePartialSetupReducer, updateSetupReducer } from 'redux/slices/setupReducer';
import { updateActiveMetaFile } from 'redux/slices/ledgerFileConfigReducer';
import { loadTempRecords } from 'redux/slices/importRecordReducer';
import { updateImportFirstLoad } from 'redux/slices/importFirstLoad';
import _ from 'lodash';
import { updateApiSetupReducer } from 'redux/slices/setupApiReducer';

export const setFileList = (fileList: any, activeFile: any) => (dispatch: any) => {
  if (activeFile) {
    // Set active file configuration
    activeFile = fixFileConfig(activeFile);
    dispatch(updateActiveMetaFile(activeFile));
  }
  dispatch(setFile(fileList));
};

const fixFileConfig = (file: any) => {
  if (!file.hasOwnProperty('activeDashboard')) {
    file.activeDashboard = 'Records';
  }
  if (!file.hasOwnProperty('dashboardViewby')) {
    file.dashboardViewby = 'Deal date';
  }
  return file;
};

export const updateDeleteFile = (fileId: any) => (dispatch: any, getState: any) => {
  dispatch(deleteFile(fileId));
  if (getState().ledgerFileConfigReducer.file_id === fileId) {
    dispatch(updateActiveMetaFile(null));
  }
};

const getCurrentTime = () => {
  var today = new Date();
  var date = today.toDateString();
  var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  return date + ' ' + time;
};

export const logFileLastUpdate = (file: any) => (dispatch: any) => {
  let updatedFile = { ...file, lastUpdated: getCurrentTime() };
  updateFileMetaData(updatedFile)
    .then((response) => {
      dispatch(logFileLastUpdateReducer(updatedFile));
    })
    .catch((error) => dispatch(addError(error)));
};

export const updateFileMetaInStore = (file: any) => (dispatch: any) => {
  let updatedFile = { ...file, lastUpdated: getCurrentTime() };
  dispatch(logFileLastUpdateReducer(updatedFile));
};

export const setFileRecords = (records: any, setup: any, customer_id: any) => (dispatch: any) => {
  const newRecords = fixRecordsDate(records);
  const newSetup = fixSetup(setup, customer_id, dispatch);
  const newRecords_2 = fixRecords(newRecords);
  dispatch(setRecords([]));
  dispatch(setRecords(newRecords_2));
  dispatch(updatePartialSetupReducer(newSetup));
  dispatch(loadTempRecords([]));
  dispatch(updateImportFirstLoad(true));
};

const fixRecordsDate = (records: any) => {
  let newRecords = records.map((record: any) => {
    let _date;
    let fullYear;

    if (record?.date < 0) {
      _date = new Date(0);
      fullYear = _date.getFullYear();
    } else {
      _date = new Date(record?.date);
      fullYear = _date.getFullYear();
    }
    if (fullYear < 100) {
      fullYear = 2000 + fullYear;
      _date.setFullYear(fullYear);
      record.date = _date.getTime();
    }
    return record;
  });
  return newRecords;
};

export const updateSetup = (newSetup: any) => (dispatch: any) => {
  //* this was commented before
  //newSetup.filters = updateFilterProperties(newSetup.filters, newSetup);
  //* this was commented before
  dispatch(updateSetupReducer(newSetup));
};

export const updateApiSetup = (newSetup: any) => (dispatch: any) => {
  //* this was commented before
  //newSetup.filters = updateFilterProperties(newSetup.filters, newSetup);
  //* this was commented before
  dispatch(updateApiSetupReducer(newSetup));
};

export const fixRecords = (records: any) => {
  records = records.filter((record: any) => record.hasOwnProperty('subRecords'));
  const mapRecords = records.map((record: any) => {
    const recordCopy = { ...record };
    if (!recordCopy.recordType) {
      recordCopy.recordType = 'general';
      recordCopy.propertyDealDetails = {
        dealDate: null,
        dealCloseDate: null,
        payoutDate: null,
        dealStatus: 'Outstanding', //* Outstanding, Paid
        client1: { name: '', email: '', phone: '' },
        client2: { name: '', email: '', phone: '' },
        notes: ''
      };
    }
    return recordCopy;
  });
  return mapRecords;
};

const fixSetup = (setup: any, customer_id: any, dispatch: any) => {
  const setupCopy = { ...setup };
  //* fixing description
  const descriptions = setupCopy.descriptionCollection;
  if (descriptions.length > 0) {
    if (!descriptions[0].hasOwnProperty('categoryTags')) {
      let newDescriptions = [];
      for (var i = 0; i < descriptions.length; i++) {
        newDescriptions.push({
          id: i.toString(),

          name: descriptions[i],
          categoryTags: []
        });
      }
      setupCopy.descriptionCollection = newDescriptions;
    }
  }

  //* fixing financial month
  if (!setupCopy.financialMonth) {
    setupCopy.financialMonth = 0;
  }

  //*/ fixing filters

  const defaultFilters = {
    accountValue: ['All'],
    classValue: ['All'],
    categoryValue: ['All'],
    dateComparatorValue: 'All',
    transactionType: 'All',
    rec: 'All',
    selectedDate1: new Date(),
    selectedDate2: new Date(),
    searchByValue: 'Description',
    searchByComparatorsValue: 'Equals',
    searchValue: '',
    showBookmarks: false
  };
  const setupCopy_copy = _.cloneDeep(setupCopy);
  if (!setupCopy_copy.fileFilters) {
    setupCopy_copy.fileFilters = [
      {
        user: customer_id,
        filters: updateFilterProperties(defaultFilters, setupCopy_copy)
      }
    ];
  } else if (
    !setupCopy_copy.fileFilters.find((userFilter: any) => userFilter.user === customer_id)
  ) {
    setupCopy_copy.fileFilters.push({
      user: customer_id,
      filters: updateFilterProperties(defaultFilters, setupCopy_copy)
    });
  }
  const filterIndex = setupCopy_copy.fileFilters.findIndex(
    (fileFilter: any) => fileFilter.user === customer_id
  );

  dispatch(setFilterIndex(filterIndex));

  //* fixing criteria
  if (!setupCopy_copy.criteriaStatements) {
    setupCopy_copy.criteriaStatements = [];
  }

  if (!setupCopy_copy.criteriaInvoiceStatements) {
    setupCopy_copy.criteriaInvoiceStatements = [];
  }

  //// fixing imported data

  if (!setupCopy_copy.importProps) {
    setupCopy_copy.importProps = importDataStructure;
  }

  if (!setupCopy_copy.selectedDashboardYear) {
    setupCopy_copy.selectedDashboardYear = new Date().getFullYear();
  }

  if (setupCopy_copy.dateFormat === 'mm/dd/yyyy') {
    setupCopy_copy.dateFormat = 'MM/dd/yyyy';
  }

  if (setupCopy_copy.dateFormat === 'dd/mm/yyyy') {
    setupCopy_copy.dateFormat = 'dd/MM/yyyy';
  }

  return setupCopy_copy;
};

export const addNote = (note: any) => (dispatch: any) => {
  dispatch(addNoteReducer(note));
  dispatch(updateActiveNoteIdReducer(note.id));
};

export const updateActiveNoteId = (activeNoteId: any) => (dispatch: any) => {
  dispatch(updateActiveNoteIdReducer(activeNoteId));
};

export const addMessage = (sender: any) => (dispatch: any) => {
  fetch('https://hplussport.com/api/products/order/price/sort/asc/qty/1')
    .then((data) => data.json())
    .then((result) => {
      // dispatch({
      //   type: 'ADD_MESSAGE',
      //   payload: {
      //     sender: sender,
      //     message: result[0].description
      //   }
      // });
    });
};
